var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-checkin-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t('checkin.form.title.personal_details')))])]),_c('div',{staticClass:"form-checkin-block"},[_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"SelectInput","additionalClass":"triple","defaultValue":_vm.defaultValues['title'] || null,"parameters":{
          name: 'title',
          title: _vm.$t('checkin.form.title.label'),
          placeholder: _vm.$t('checkin.form.title.placeholder'),
          options: _vm.personalTitlesSet,
          wholepage: false,
        }},on:{"input":function (value) { return _vm.title = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","validationRules":"min:2","additionalClass":"triple","defaultValue":_vm.defaultValues['firstName'] || null,"parameters":{
          name: 'firstName',
          title: _vm.$t('checkin.form.firstname.label'),
          placeholder: _vm.$t('checkin.form.firstname.placeholder'),
        }},on:{"input":function (value) { return _vm.firstName = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","validationRules":"min:2","additionalClass":"triple","defaultValue":_vm.defaultValues['middleName'] || null,"parameters":{
          name: 'middleName',
          title: _vm.$t('checkin.form.middlename.label'),
          placeholder: _vm.$t('checkin.form.middlename.placeholder'),
        }},on:{"input":function (value) { return _vm.middleName = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","validationRules":"min:2","additionalClass":"triple","defaultValue":_vm.defaultValues['lastName'] || null,"parameters":{
          name: 'lastName',
          title: _vm.$t('checkin.form.surname.label'),
          placeholder: _vm.$t('checkin.form.surname.placeholder'),
        }},on:{"input":function (value) { return _vm.lastName = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"SelectInput","defaultValue":_vm.defaultValues['nationality'] || null,"parameters":{
          name: 'nationality',
          title: _vm.$t('checkin.form.nationality.label'),
          placeholder: _vm.$t('checkin.form.select.select_option') + ' ' + _vm.$t('checkin.form.nationality.label').toLowerCase(),
          options: _vm.statesSet,
          orderBy: 'label',
        }},on:{"input":function (value) { return _vm.nationality = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"DateInput","validationRules":"date","defaultValue":_vm.defaultValues['dateOfBirth'] || null,"parameters":{
          name: 'dateOfBirth',
          title: _vm.$t('checkin.form.dateOfBirth.label'),
          placeholder: _vm.$t('checkin.form.dateOfBirth.placeholder'),
        }},on:{"input":function (value) { return _vm.dateOfBirth = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"SelectInput","defaultValue":_vm.defaultValues['gender'] || null,"parameters":{
          name: 'gender',
          title: _vm.$t('checkin.form.gender.label'),
          placeholder: _vm.$t('checkin.form.select.select_option') + ' ' + _vm.$t('checkin.form.gender.label').toLowerCase(),
          options: _vm.gendersSet,
          wholepage: false,
        }},on:{"input":function (value) { return _vm.gender = value; }}})],1),_c('div',{staticClass:"form-button-center"},[_c('ButtonInput',{attrs:{"type":"button","disablesd":_vm.invalid,"loading":_vm.formSending},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('checkin.action.continue'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }