<template>
  <div>
    <div class="form-checkin-title">
      <h1>{{ $t('checkin.form.title.signature') }}</h1>
    </div>
    <div class="form-checkin-block">
      <VueSignatureCanvas
        ref="sigCanvas"
        penColor="black"
        :clearOnResize="true"
        :canvasProps="{class: 'sig-canvas'}"
      />
    </div>

    <div class="form-button-center">
      <ButtonInput type="button" view="outline" @click="resetCanvas" :disabled="!canContinue || formSending">
        {{ $t('checkin.action.try_again') }}
      </ButtonInput>

      <ButtonInput type="button" @click="onSubmit" :disabled="!canContinue || formSending">
        {{ $t('checkin.action.continue') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import CheckInFormBaseContent from './CheckInFormBaseContent';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import VueSignatureCanvas from 'vue-signature-canvas';

export default {
  name: 'OtherDetails',
  extends: CheckInFormBaseContent,
  components: {
    ButtonInput,
    VueSignatureCanvas,
  },
  mounted() {
    this.onCanvasEndInterval = setInterval(this.onCanvasEnd, 100);
  },
  beforeDestroy() {
    if (this.onCanvasEndInterval) {
      clearInterval(this.onCanvasEndInterval);
    }
  },
  props: {
    formSending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onCanvasEndInterval: null,
      canContinue: false,
    };
  },
  methods: {
    onCanvasEnd() {
      if (!this.$refs.sigCanvas.isEmpty()) {
        this.canContinue = true;
      }
    },
    resetCanvas() {
      this.canContinue = false;
      this.$refs.sigCanvas.clear();
    },
    onSubmit() {
      this.$emit('submit', {
        signature: this.$refs.sigCanvas.getTrimmedCanvas().toDataURL(),
      });
    },
  },
};
</script>

<style lang="scss">
  .sig-canvas {
    width: 100%;
    max-width: 80vh;
    height: 40vh;
    background-color: #fff;
  }
</style>
