<template>
  <label class="form-control" :class="{'wholepage-select': wholepage}">
    <span v-if="title" :class="{'required': required}">{{ title }}</span>
    <v-select :name="name"
      :class="{
        'form-input': true,
        'invalid': error,
      }"
      :disabled="disabled"
      :placeholder="placeholder"
      :options="optionsOrdered"
      :clearable="false"
      :searchable="searchable"
      :filter="filterSelect"
      @input="updateValueSelect"
    >
      <!-- <option :value="null" :selected="!value">{{ placeholder }}</option>
      <option v-for="(option, index) in optionsOrdered"
        :key="index"
        :selected="value && option.value == value"
        :value="option.value">
        {{ option.label }}
      </option> -->
    </v-select>
    <span v-if="error" class="error-message">
      <slot name="error">
        {{ error }}
      </slot>
    </span>
  </label>
</template>

<script>
import _ from 'lodash';
import 'vue-select/dist/vue-select.css';
import Input from './Input';

export default {
  name: 'SelectInput',
  extends: Input,
  props: {
    options: {
      type: Array,
      required: true,
    },
    orderBy: {
      type: String,
      required: false,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    wholepage: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    optionsOrdered() {
      const options = _.map(this.options, (option) => ({
        label: this.$t(option.label),
        code: option.value,
      }));

      if (!this.orderBy) {
        return options;
      }

      return options.slice().sort((a, b) => a.label.localeCompare(b.label));
    },
    valueSelected() {
      const value = _.find(this.options, (option) => option.value === this.value);
      if (!value) {
        return null;
      }
      return value.value;
    },
  },
  methods: {
    updateValueSelect(value) {
      return this.updateValue(value.code);
    },
    filterSelect(options, search) {
      return _.filter(options, (option) => {
        let label = option.label;
        if (typeof label === 'number') {
          label = label.toString();
        }
        label = this.toLowerCaseAndStrip(label);
        var re = new RegExp(this.toLowerCaseAndStrip(search), 'gi');
        return !!label.match(re);
      });
    },
    toLowerCaseAndStrip(string) {
      return string
        .replace(/[ ]/g, '_')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^A-Z_]/ig, '')
        .toLowerCase();
    },
  },
};
</script>

<style lang="scss">
.v-select {
  padding-right: 0.5rem;
}
.vs__dropdown-menu {
  max-height: 7rem !important;
}
.vs__dropdown-toggle {
  border: none;
  height: 100%;
}

.vs--single .vs__selected,
.vs--single.vs--loading .vs__selected,
.vs--single.vs--open .vs__selected {
  line-height: 2.5rem;
}

.wholepage-select .v-select [role=listbox] {
  position: fixed;
  top: 10vh;
  bottom: 10vh;
  height: 85vh;
  z-index: 90;
  max-height: 85vh !important;
  max-width: 50rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
