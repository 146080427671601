<template>
  <div>
    <div class="form-checkin-title">
      <h1>{{ title }}</h1>
    </div>
    <div v-if="side === 1" class="form-checkin-block">
      <Camera
          v-if="!idScan"
          :showBorder="true"
          :scanInfo="scanInfo"
          :side="side"
          @loading="(status) => loading = status"
          @input="processScan"
      />
      <div v-else class="scan-preview-block">
        <img :src="idScan" alt="Scan document preview" class="scan-preview" />

        <div class="reset-button-box">
          <ButtonInput type="button" @click="resetScan" view="outline">
            {{ $t('checkin.action.scan.reset') }}
          </ButtonInput>
        </div>
      </div>
    </div>
    <div v-if="side === 2" class="form-checkin-block">
      <Camera
          v-if="!idScanSecond"
          :showBorder="true"
          :scanInfo="scanInfo"
          :side="side"
          @loading="(status) => loading = status"
          @input="processScan"
      />
      <div v-else class="scan-preview-block">
        <img :src="idScan" alt="Scan document preview" class="scan-preview" />
        <img :src="idScanSecond" alt="Scan document preview" class="scan-preview" />

        <div class="reset-button-box">
          <ButtonInput type="button" @click="resetScan" view="outline">
            {{ $t('checkin.action.scan.reset') }}
          </ButtonInput>
        </div>
      </div>
    </div>

    <div class="form-button-center" v-if="this.idScanComplete">
      <ButtonInput type="button" view="outline" :loading="formSending" @click="$emit('back')">
        {{ $t('checkin.action.back') }}
      </ButtonInput>

      <ButtonInput type="button" :disabled="redirectLoading" :loading="formSending || redirectLoading" @click="onSubmit">
        {{ $t('checkin.action.continue') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Jimp from 'jimp';
import mergeImg from 'merge-base64';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import CheckInFormBaseContent from './CheckInFormBaseContent';
import {
  checkInInputsSet,
} from '../consts';
import Camera from '@/components/Camera';
import WatermarkImage from '@/assets/images/watermark.png';

export default {
  name: 'VisaDetails',
  extends: CheckInFormBaseContent,
  components: {
    ButtonInput,
    Camera,
  },
  props: {
    typeOfDocumentSelected: {
      type: String,
      required: false,
    },
  },
  data() {
    const data = {};
    _.each(checkInInputsSet['documentScan'], (itemKey) => {
      data[itemKey] = '';
    });

    return ({
      ...data,
      side: 1,
      idScanComplete: false,
      loading: true,
      redirectLoading: false,
    });
  },
  computed: {
    title() {
      if (this.idScanComplete) {
        return this.$t('checkin.form.title.document_scan_check');
      }
      if (!this.typeOfDocumentSelected) {
        return this.$t('checkin.form.title.document_scan');
      }
      return this.typeOfDocumentSelected === 'id'
        ? this.$t('checkin.form.title.document_scan_id') + ' ' + this.side
        : this.$t('checkin.form.title.document_scan_passport');
    },
    scanInfo() {
      if (this.typeOfDocumentSelected === 'passport') {
        return this.$t('checkin.document_scan.description');
      }
      return this.side === 1
        ? this.$t('checkin.document_scan.first_side_description')
        : this.$t('checkin.document_scan.second_side_description');
    },
  },
  methods: {
    async processScan(base64data) {
      this.loading = true;

      const splitted = base64data.split(',');
      const [, splittedFileBase64] = splitted;

      Jimp.read(Buffer.from(splittedFileBase64, 'base64')).then(async (image) => {
        image.quality(80);
        await image.resize(800, Jimp.AUTO);

        const mask = await Jimp.read(WatermarkImage);
        image.blit(mask, 0, 0);

        if (this.side === 1) {
          this.idScan = await image.getBase64Async(image.getMIME());
          if (this.typeOfDocumentSelected === 'id') {
            this.side = 2;
          } else {
            this.idScanComplete = true;
          }
        } else if (this.side === 2) {
          this.idScanComplete = true;
          this.idScanSecond = await image.getBase64Async(image.getMIME());
        }
        this.loading = false;
      });
    },
    resetScan() {
      this.side = 1;
      this.idScan = null;
      this.idScanSecond = null;
      this.idScanComplete = false;
      this.loading = false;
    },
    onSubmit() {
      this.redirectLoading = true;
      if (!this.idScanSecond) {
        this.submitScan();
      }

      const idScanSplitted = this.idScan.split(',');
      const idScanSecondSplitted = this.idScanSecond.split(',');
      mergeImg([idScanSplitted[1], idScanSecondSplitted[1]])
        .then((base64data) => {
          this.idScan = base64data;

          this.submitScan();
        });
    },
    submitScan() {
      const data = _.clone(this._data);
      delete data['side'];
      delete data['idScanComplete'];
      delete data['loading'];
      delete data['idScanSecond'];
      this.redirectLoading = false;
      this.$emit('submit', data);
    },
  },
};
</script>
