var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-checkin-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t('checkin.form.title.document_details')))])]),_c('div',{staticClass:"form-checkin-block"},[_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","validationRules":"min:5","defaultValue":_vm.defaultValues['iDNumber'] || null,"parameters":{
          name: 'iDNumber',
          title: _vm.$t('checkin.form.iDNumber.label'),
          placeholder: _vm.$t('checkin.form.iDNumber.placeholder'),
        }},on:{"input":function (value) { return _vm.iDNumber = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"SelectInput","defaultValue":_vm.defaultValues['typeOfDocument'] || null,"parameters":{
          name: 'typeOfDocument',
          title: _vm.$t('checkin.form.typesOfDocument.label'),
          placeholder: _vm.$t('checkin.form.select.select_option') + ' ' + _vm.$t('checkin.form.typesOfDocument.label').toLowerCase(),
          options: _vm.typesOfDocumentSet,
          wholepage: false,
        }},on:{"input":function (value) { return _vm.typeOfDocument = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"DateInput","validationRules":"date","defaultValue":_vm.defaultValues['passportIssueDate'] || null,"parameters":{
          name: 'passportIssueDate',
          title: _vm.$t('checkin.form.passportIssueDate.label'),
          placeholder: _vm.$t('checkin.form.passportIssueDate.placeholder'),
        }},on:{"input":function (value) { return _vm.passportIssueDate = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"DateInput","validationRules":"date","defaultValue":_vm.defaultValues['passportExpirationDate'] || null,"parameters":{
          name: 'passportExpirationDate',
          title: _vm.$t('checkin.form.passportExpirationDate.label'),
          placeholder: _vm.$t('checkin.form.passportExpirationDate.placeholder'),
        }},on:{"input":function (value) { return _vm.passportExpirationDate = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"SelectInput","defaultValue":_vm.defaultValues['countryIssuingPassport'] || null,"parameters":{
          name: 'countryIssuingPassport',
          title: _vm.$t('checkin.form.countryIssuingPassport.label'),
          placeholder: _vm.$t('checkin.form.select.select_option') + ' ' + _vm.$t('checkin.form.countryIssuingPassport.label').toLowerCase(),
          options: _vm.statesSet,
          orderBy: 'label',
        }},on:{"input":function (value) { return _vm.countryIssuingPassport = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","forceRequired":_vm.visaIsRequired,"validationRules":"min:7","defaultValue":_vm.defaultValues['visaNumber'] || null,"parameters":{
          name: 'visaNumber',
          title: _vm.$t('checkin.form.visaNumber.label'),
          placeholder: _vm.$t('checkin.form.visaNumber.placeholder'),
        }},on:{"input":function (value) { return _vm.visaNumber = value; }}})],1),_c('div',{staticClass:"form-button-center"},[_c('ButtonInput',{attrs:{"type":"button","view":"outline","loading":_vm.formSending},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" "+_vm._s(_vm.$t('checkin.action.back'))+" ")]),_c('ButtonInput',{attrs:{"type":"button","disablesd":_vm.invalid,"loading":_vm.formSending},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('checkin.action.continue'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }