var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-checkin-title purpose-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t('checkin.form.purposeOfStay.label')))]),_c('h3',{staticClass:"title-required"},[_vm._v("*")])]),_c('div',{staticClass:"form-checkin-block"},[_c('div',{staticClass:"clearfix"}),_c('br'),(_vm.purposesOfStaySet && _vm.purposesOfStaySet.length > 0)?_c('div',{staticClass:"purposes-of-stay-list"},_vm._l((_vm.purposesOfStaySet),function(purpose){return _c('CheckInFormInput',{key:purpose.key,attrs:{"additionalClass":"triple","alreadySent":_vm.alreadySent,"componentType":"Input","forceRequired":"","forceError":_vm.purposeOfStay ? null : 'required',"parameters":{
            name: 'purposeOfStay',
            type: 'radio',
            title: purpose.title,
            value: purpose.key,
          }},on:{"input":function () { return _vm.purposeOfStay = purpose.key; }}})}),1):_vm._e(),_c('CheckInFormInput',{attrs:{"additionalClass":"wide","alreadySent":_vm.alreadySent,"componentType":"Input","forceRequired":"","parameters":{
          name: 'agreement',
          type: 'checkbox',
          title: _vm.$t('checkin.form.agreement'),
        }},on:{"input":function () { return _vm.agreement = !_vm.agreement; }}})],1),_c('div',{staticClass:"form-button-center"},[_c('ButtonInput',{attrs:{"type":"button","view":"outline","loading":_vm.formSending},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" "+_vm._s(_vm.$t('checkin.action.back'))+" ")]),_c('ButtonInput',{attrs:{"type":"button","disablesd":_vm.invalid,"loading":_vm.formSending},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('checkin.action.continue'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }