<template>
  <label class="form-control">
    <span v-if="title" :class="{'required': required}">{{ title }}</span>
    <div class="date-time-selection">
      <SelectInput
          v-model="hour"
          :name="`time_hour_${index}`"
          :placeholder="$t('form.time_input.hour_select_placeholder')"
          :error="error"
          :options="hoursSet"
          class="date-time-select date-time-select--hour"
          @input="changeTime"
      />
      <SelectInput
          v-model="minute"
          :name="`time_minute_${index}`"
          :placeholder="$t('form.time_input.minute_select_placeholder')"
          :error="error"
          :options="minutesSet"
          class="date-time-select date-time-select--minute"
          @input="changeTime"
      />
    </div>
    <span v-if="error" class="error-message">
      <slot name="error">
        {{ error }}
      </slot>
    </span>
  </label>
</template>

<script>
import Input from './Input';
import SelectInput from './SelectInput';

export default {
  name: 'TimeInput',
  extends: Input,
  components: {
    SelectInput,
  },
  mounted() {
    if (this.value) {
      const timeParts = this.value.split(':');
      if (timeParts.length === 2) {
        const hourPart = parseInt(timeParts[0], 10);
        const minutePart = parseInt(timeParts[1], 10);

        if (hourPart <= 24 && hourPart >= 0 && (minutePart === 15 || minutePart === 30 || minutePart === 45)) {
          this.hour = hourPart.toString();
          this.minute = minutePart.toString();
          this.changeTime();
        }
      }
    }
  },
  data() {
    const hoursSet = [];
    for (let i = 1; i <= 24; i++) {
      hoursSet.push({
        label: i,
        value: i,
      });
    }

    const minutesSet = [];
    for (let i = 0; i <= 45; i += 15) {
      minutesSet.push({
        label: i < 10 ? '0' + i : i,
        value: i,
      });
    }

    return {
      index: Math.round(Math.random()*1000000000),
      hour: null,
      minute: null,
      hoursSet,
      minutesSet,
    };
  },
  methods: {
    changeTime() {
      if (this.hour && this.minute) {
        const hour = this.hour < 10 ? '0' + this.hour : this.hour;
        const minute = this.minute < 10 ? '0' + this.minute : this.minute;
        this.$emit('input', hour + ':' + minute);
        return;
      }
      this.$emit('input', '');
    },
  },
};
</script>
