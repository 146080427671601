var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-checkin-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t('checkin.form.title.other_details')))])]),_c('div',{staticClass:"form-checkin-block"},[_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","defaultValue":_vm.defaultValues['carPlateNumber'] || null,"validationRules":"min:5","parameters":{
          name: 'carPlateNumber',
          title: _vm.$t('checkin.form.carPlateNumber.label'),
          placeholder: _vm.$t('checkin.form.carPlateNumber.placeholder'),
        }},on:{"input":function (value) { return _vm.carPlateNumber = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","defaultValue":_vm.defaultValues['numberOfFlight'] || null,"validationRules":"min:2","parameters":{
          name: 'numberOfFlight',
          title: _vm.$t('checkin.form.numberOfFlight.label'),
          placeholder: _vm.$t('checkin.form.numberOfFlight.placeholder'),
        }},on:{"input":function (value) { return _vm.numberOfFlight = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"TimeInput","defaultValue":_vm.defaultValues['timeOfArrival'] || null,"parameters":{
          name: 'timeOfArrival',
          title: _vm.$t('checkin.form.timeOfArrival.label'),
          placeholder: _vm.$t('checkin.form.timeOfArrival.placeholder'),
        }},on:{"input":function (value) { return _vm.timeOfArrival = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","defaultValue":_vm.defaultValues['occupation'] || null,"parameters":{
          name: 'occupation',
          title: _vm.$t('checkin.form.occupation.label'),
          placeholder: _vm.$t('checkin.form.occupation.placeholder'),
        }},on:{"input":function (value) { return _vm.occupation = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","defaultValue":_vm.defaultValues['nextDestination'] || null,"parameters":{
          name: 'nextDestination',
          title: _vm.$t('checkin.form.nextDestination.label'),
          placeholder: _vm.$t('checkin.form.nextDestination.placeholder'),
        }},on:{"input":function (value) { return _vm.nextDestination = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"DateInput","defaultValue":_vm.defaultValues['dateOfCountryEnter'] || null,"parameters":{
          name: 'dateOfCountryEnter',
          title: _vm.$t('checkin.form.dateOfCountryEnter.label'),
        }},on:{"input":function (value) { return _vm.dateOfCountryEnter = value; }}})],1),_c('div',{staticClass:"form-button-center"},[_c('ButtonInput',{attrs:{"type":"button","view":"outline","loading":_vm.formSending},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" "+_vm._s(_vm.$t('checkin.action.back'))+" ")]),_c('ButtonInput',{attrs:{"type":"button","disablesd":_vm.invalid,"loading":_vm.formSending},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('checkin.action.continue'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }