<template>
  <label :class="`form-control form-control--${type}`">
    <span v-if="title && type !== 'radio' && type !== 'checkbox'" :class="{'required': required}">{{ title }}</span>
    <vue-tel-input ref="telInput"
      :value="value"
      :required="required"
      :class="{error: !!error}"
      :defaultCountry="defaultCountry"
      :autoDefaultCountry="false"
      @input="updateValue"
      :dropdownOptions="{
        disabled: true,
      }"
      :inputOptions="{
        showDialCode: true,
      }"
      mode="international"
    ></vue-tel-input>
    <span v-if="title && (type === 'radio' || type === 'checkbox')" :class="{'required': required}">{{ title }}</span>
    <span v-if="error" class="error-message">
      <slot name="error">
        {{ error }}
      </slot>
    </span>
  </label>
</template>

<script>
import Input from './Input';

export default {
  name: 'PhoneInput',
  extends: Input,
  props: {
    defaultCountry: {
      type: String,
      required: false,
    },
  },
  watch: {
    defaultCountry(defaultCountry) {
      this.updateValue('', {
        number: '',
        valid: false,
      });
      this.$refs.telInput.activeCountryCode = defaultCountry;
    },
  },
  methods: {
    updateValue(number, phone) {
      return this.$emit('input', {
        value: number || '',
        formattedPhoneNumber: phone.number || null,
        isValid: !!phone.valid,
      });
    },
  },
};
</script>

<style lang="scss">
.form-control .vue-tel-input {
  width: 100%;
  height: 3.125rem;
  text-align: left;
  border-color: #e2e2e2;
  border-radius: 0.5rem;

  .vti__dropdown {
    display: none;
  }

  &.error {
    border-color: $red-color !important;
  }

  .vti__input {
    height: 3rem;
    color: #4a4a4a;
    font-size: 1rem;
    line-height: 1rem;
    border-color: transparent !important;
    box-shadow: none !important;
    border-radius: 0.5rem;
    padding: 0 2rem 0 1rem;
  }
}

</style>
