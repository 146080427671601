var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-checkin-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t('checkin.form.title.contact_details')))])]),_c('div',{staticClass:"form-checkin-block"},[_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"PhoneInput","defaultValue":_vm.defaultValues['phone'] || null,"forceError":_vm.phoneError ? _vm.$t('checkin.form.phone.invalid_phone') : null,"parameters":{
          name: 'phone',
          title: _vm.$t('checkin.form.phone.label'),
          placeholder: _vm.$t('checkin.form.phone.placeholder'),
          defaultCountry: _vm.selectedNationality,
        }},on:{"input":_vm.updatePhoneNumber}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","defaultValue":_vm.defaultValues['email'] || null,"validationRules":"email","parameters":{
          name: 'email',
          title: _vm.$t('checkin.form.email.label'),
          placeholder: _vm.$t('checkin.form.email.placeholder'),
        }},on:{"input":function (value) { return _vm.email = value; }}}),_c('div',{staticClass:"clearfix"}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"SelectInput","defaultValue":_vm.defaultValues['residence'] || null,"parameters":{
          name: 'residence',
          title: _vm.$t('checkin.form.country_residence.label'),
          placeholder: _vm.$t('checkin.form.select.select_option') + ' ' + _vm.$t('checkin.form.country_residence.label').toLowerCase(),
          options: _vm.statesSet,
          orderBy: 'label',
        }},on:{"input":function (value) { return _vm.residence = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","defaultValue":_vm.defaultValues['street'] || null,"validationRules":"min:2","parameters":{
          name: 'street',
          title: _vm.$t('checkin.form.address.label'),
          placeholder: _vm.$t('checkin.form.address.placeholder'),
        }},on:{"input":function (value) { return _vm.street = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","defaultValue":_vm.defaultValues['city'] || null,"validationRules":"min:2","parameters":{
          name: 'city',
          title: _vm.$t('checkin.form.city.label'),
          placeholder: _vm.$t('checkin.form.city.placeholder'),
        }},on:{"input":function (value) { return _vm.city = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","defaultValue":_vm.defaultValues['zip'] || null,"validationRules":"min:4","parameters":{
          name: 'zip',
          title: _vm.$t('checkin.form.zip.label'),
          placeholder: _vm.$t('checkin.form.zip.placeholder'),
        }},on:{"input":function (value) { return _vm.zip = value; }}})],1),_c('div',{staticClass:"form-button-center"},[_c('ButtonInput',{attrs:{"type":"button","view":"outline","loading":_vm.formSending},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" "+_vm._s(_vm.$t('checkin.action.back'))+" ")]),_c('ButtonInput',{attrs:{"type":"button","disablesd":_vm.invalid,"loading":_vm.formSending},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('checkin.action.continue'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }