<template>
  <div>
    <div class="form-checkin-title">
      <h3>{{ $t('checkin.form.title.other_details') }}</h3>
    </div>
    <div class="form-checkin-block">
      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :defaultValue="defaultValues['carPlateNumber'] || null"
          validationRules="min:5"
          @input="(value) => carPlateNumber = value"
          :parameters="{
            name: 'carPlateNumber',
            title: $t('checkin.form.carPlateNumber.label'),
            placeholder: $t('checkin.form.carPlateNumber.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :defaultValue="defaultValues['numberOfFlight'] || null"
          validationRules="min:2"
          @input="(value) => numberOfFlight = value"
          :parameters="{
            name: 'numberOfFlight',
            title: $t('checkin.form.numberOfFlight.label'),
            placeholder: $t('checkin.form.numberOfFlight.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="TimeInput"
          :defaultValue="defaultValues['timeOfArrival'] || null"
          @input="(value) => timeOfArrival = value"
          :parameters="{
            name: 'timeOfArrival',
            title: $t('checkin.form.timeOfArrival.label'),
            placeholder: $t('checkin.form.timeOfArrival.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :defaultValue="defaultValues['occupation'] || null"
          @input="(value) => occupation = value"
          :parameters="{
            name: 'occupation',
            title: $t('checkin.form.occupation.label'),
            placeholder: $t('checkin.form.occupation.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :defaultValue="defaultValues['nextDestination'] || null"
          @input="(value) => nextDestination = value"
          :parameters="{
            name: 'nextDestination',
            title: $t('checkin.form.nextDestination.label'),
            placeholder: $t('checkin.form.nextDestination.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="DateInput"
          :defaultValue="defaultValues['dateOfCountryEnter'] || null"
          @input="(value) => dateOfCountryEnter = value"
          :parameters="{
            name: 'dateOfCountryEnter',
            title: $t('checkin.form.dateOfCountryEnter.label'),
          }"
      />
    </div>

    <div class="form-button-center">
      <ButtonInput type="button" view="outline" :loading="formSending" @click="$emit('back')">
        {{ $t('checkin.action.back') }}
      </ButtonInput>

      <ButtonInput type="button" :disablesd="invalid" :loading="formSending" @click="onSubmit">
        {{ $t('checkin.action.continue') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import CheckInFormBaseContent from './CheckInFormBaseContent';
import CheckInFormInput from '../CheckInFormInput';
import {
  checkInInputsSet,
} from '../consts';

export default {
  name: 'OtherDetails',
  extends: CheckInFormBaseContent,
  components: {
    ButtonInput,
    CheckInFormInput,
  },
  data() {
    const data = {};
    _.each(checkInInputsSet['otherDetails'], (itemKey) => {
      data[itemKey] = '';
    });

    return ({
      ...data,
    });
  },
  methods: {
    onSubmit() {
      const data = _.clone(this._data);
      this.$emit('submit', data);
    },
  },
};
</script>
