<template>
  <div>
    <div class="form-checkin-title">
      <h3>{{ $t('checkin.form.title.contact_details') }}</h3>
    </div>
    <div class="form-checkin-block">
      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="PhoneInput"
          :defaultValue="defaultValues['phone'] || null"
          :forceError="phoneError ? $t('checkin.form.phone.invalid_phone') : null"
          @input="updatePhoneNumber"
          :parameters="{
            name: 'phone',
            title: $t('checkin.form.phone.label'),
            placeholder: $t('checkin.form.phone.placeholder'),
            defaultCountry: selectedNationality,
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :defaultValue="defaultValues['email'] || null"
          validationRules="email"
          @input="(value) => email = value"
          :parameters="{
            name: 'email',
            title: $t('checkin.form.email.label'),
            placeholder: $t('checkin.form.email.placeholder'),
          }"
      />

      <div class="clearfix" />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="SelectInput"
          :defaultValue="defaultValues['residence'] || null"
          @input="(value) => residence = value"
          :parameters="{
            name: 'residence',
            title: $t('checkin.form.country_residence.label'),
            placeholder: $t('checkin.form.select.select_option') + ' ' + $t('checkin.form.country_residence.label').toLowerCase(),
            options: statesSet,
            orderBy: 'label',
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :defaultValue="defaultValues['street'] || null"
          validationRules="min:2"
          @input="(value) => street = value"
          :parameters="{
            name: 'street',
            title: $t('checkin.form.address.label'),
            placeholder: $t('checkin.form.address.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :defaultValue="defaultValues['city'] || null"
          validationRules="min:2"
          @input="(value) => city = value"
          :parameters="{
            name: 'city',
            title: $t('checkin.form.city.label'),
            placeholder: $t('checkin.form.city.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :defaultValue="defaultValues['zip'] || null"
          validationRules="min:4"
          @input="(value) => zip = value"
          :parameters="{
            name: 'zip',
            title: $t('checkin.form.zip.label'),
            placeholder: $t('checkin.form.zip.placeholder'),
          }"
      />
    </div>

    <div class="form-button-center">
      <ButtonInput type="button" view="outline" :loading="formSending" @click="$emit('back')">
        {{ $t('checkin.action.back') }}
      </ButtonInput>

      <ButtonInput type="button" :disablesd="invalid" :loading="formSending" @click="onSubmit">
        {{ $t('checkin.action.continue') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import CheckInFormBaseContent from './CheckInFormBaseContent';
import CheckInFormInput from '../CheckInFormInput';
import { GET_CHECKIN_INPUTS } from '@/store/property';
import {
  checkInInputsSet,
  statesSet,
} from '../consts';

export default {
  name: 'AddressDetails',
  extends: CheckInFormBaseContent,
  components: {
    ButtonInput,
    CheckInFormInput,
  },
  props: {
    selectedNationality: {
      type: String,
      required: false,
    },
  },
  data() {
    const data = {};
    _.each(checkInInputsSet['addressDetails'], (itemKey) => {
      data[itemKey] = '';
    });

    return ({
      ...data,
      phoneValid: false,
      formattedPhone: null,
      statesSet,
    });
  },
  computed: {
    phoneError() {
      const checkInInputs = this.$store.getters[GET_CHECKIN_INPUTS];
      const inputSettings = _.find(checkInInputs, (checkInInput) => checkInInput.key === 'phone');
      return this.alreadySent && ((inputSettings && inputSettings.mandatory) || this.phone !== '') && !this.phoneValid;
    },
  },
  methods: {
    updatePhoneNumber({value, isValid, formattedPhoneNumber}) {
      this.phoneValid = isValid;
      this.formattedPhone = formattedPhoneNumber;
      this.phone = value;
    },
    onSubmit() {
      const data = _.clone(this._data);
      delete data['statesSet'];
      this.$emit('submit', data);
    },
  },
};
</script>
