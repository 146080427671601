<template>
  <div class="checkin">
    <h1 v-if="showMainCheckInHeader">{{ $t('checkin.main_title') }} {{(currentStepIndex)}}{{('/')}}{{Object.keys(this.usedInputSets).length}}</h1>
    <LanguageSelect absolutePosition />

    <ProfileDetail />

    <p v-if="showMainCheckInHeader" class="checkin-description">
      {{ checkInText || $t('checkin.content.description') }}
    </p>

    <CheckInForm @step="(step) => currentStep = step"
      @usedInputSets="setUsedInputSets"
      @submit="onSubmit"
    />

    <WholepageLoader v-if="loading">
      <h1 class='creating-checkin'>{{ $t('checkin.loader.saving_checkin') }}</h1>
    </WholepageLoader>

  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import * as endpoints from '@/api/handler.endpoints';
import * as methods from '@/api/handler.methods';
import api from '@/api/handler';
import flashMessage from '@/helpers/flashMessage';
import errorHandler from '@/api/errorHandler';
import LanguageSelect from '@/components/LanguageSelect/index';
import { GET_PROPERTY_DETAIL } from '@/store/property';
import {
  GET_RESERVATION_DETAIL,
  UPDATE_RESERVATION,
} from '@/store/reservation';
import { FALLBACK_LANGUAGE, ISO_CODE_TO_LANGUAGE } from '@/constants/trans';
import { logEvent } from '@/helpers/analytics';
import CheckInForm from '@/components/CheckIn/CheckInForm';
import ProfileDetail from '@/components/ProfileDetail';
import * as exceptionCode from '@/constants/exceptionsCode';
import WholepageLoader from '@/components/WholepageLoader';

export default {
  name: 'CheckInAdd',
  async mounted() {
    if (!this.reservation) {
      await this.$router.push('/home');
    }
  },
  components: {
    LanguageSelect,
    CheckInForm,
    ProfileDetail,
    WholepageLoader,
  },
  data() {
    return {
      currentStep: null,
      loading: false,
      usedInputSets: {},
    };
  },
  computed: {
    currentStepIndex() {

      let index = 1;
      let step = undefined;

      Object.values(this.usedInputSets).forEach(value => {
        if (step === undefined){
          if (value === this.currentStep || this.currentStep === null){
            step = index;
          }
          index++;
        }
      });
      return step;
    },
    ...mapGetters({
      property: GET_PROPERTY_DETAIL,
      reservation: GET_RESERVATION_DETAIL,
    }),
    checkInText() {
      const fallback = _.get(this.property, `settings.checkIn.${ISO_CODE_TO_LANGUAGE[FALLBACK_LANGUAGE]}`);
      return _.get(this.property, `settings.checkIn.${ISO_CODE_TO_LANGUAGE[this.$i18n.locale] || this.$i18n.locale}`) || fallback;
    },
    showMainCheckInHeader() {
      return [
        'visaScan',
        'documentScan',
        'termsAndConditions',
        'signature',
      ].indexOf(this.currentStep) === -1;
    },
  },
  methods: {
    onSubmit(checkInData) {
      this.alreadySent = true;
      this.loading = true;

      if (checkInData.formattedPhone && checkInData.phone) {
        checkInData.phone = checkInData.formattedPhone;
      }

      api(this, endpoints.ENDPOINT_CHECKIN, methods.POST_CHECKIN)(this.reservation.id, checkInData)
        .then((data) => {
          logEvent('onCheckInAddCreateCheckInClick');

          if (data.metadata && data.metadata.success) {
            // When we have all checkins, reload whole reservation - after reload we can get a room automatically
            if (data.data.checkins.length >= parseInt(this.reservation.total_guests_count, 10)) {
              return api(this, endpoints.ENDPOINT_RESERVATION, methods.GET_RESERVATION)(this.reservation.pin)
                .then(({data}) => {
                  return this.$store.dispatch(UPDATE_RESERVATION, data)
                    .then(() => {
                      this.$router.push({
                        name: 'checkin-list',
                      });
                    })
                    .catch(() => {
                      this.sending = false;
                    });
                })
                .catch((err) => {
                  this.handleError(err);
                  this.sending = false;
                });
            }

            return this.$store.dispatch(UPDATE_RESERVATION, data.data)
              .then(() => {
                this.$router.push({
                  name: 'checkin-list',
                });
              })
              .catch(() => {
                this.sending = false;
              });
          }

          this.loading = false;
          flashMessage(this.$store, this.$t('checkin.alert.error.failed_checkin_contact'), 'danger');
        })
        .catch((err) => {
          this.loading = false;

          let message = errorHandler(err.response);

          flashMessage(this.$store, this.$t(message) || this.$t('checkin.alert.error.failed_checkin_contact'), 'danger');
        });
    },
    handleError(err) {
      const errorCode = _.get(err, 'response.data.responseCode') || 500;
      let message = errorHandler(err.response);
      if (errorCode === 404) {
        message = this.$t('api.error.reservation_not_found');
      } else if (errorCode === 400) {
        const data = _.get(err, 'response.data.responseBody.metadata.data');
        const code = _.get(data, 'code');

        if (code === exceptionCode.RESERVATION_WITHOUT_CHECK_INS && data) {
          return this.$store.dispatch(UPDATE_RESERVATION, data)
            .then(() => {
              this.$router.push({
                name: 'checkin-list',
              });
            })
            .catch(() => {
              this.sending = false;
            });
        } else if ([
          exceptionCode.ROOM_NOT_FOUND,
          exceptionCode.STAY_ROOM_MISMATCH,
          exceptionCode.DATA_ROOM_MISSING,
          exceptionCode.ROOM_MISSING_IN_PMS,
          exceptionCode.NO_AVAILABLE_ROOM_TO_ASSING,
          exceptionCode.ROOM_NOT_CLEAN,
          exceptionCode.RESERVATION_WITHOUT_ROOMS,
          exceptionCode.DATE_STAY_NOT_ACTIVE,
        ].indexOf(code) >= 0) {
          return this.$store.dispatch(UPDATE_RESERVATION, {reservation: data})
            .then(() => {
              this.$router.push({
                name: 'no-rooms-reservation',
              });
            })
            .catch(() => {
              this.sending = false;
            });
        }
      }

      if (!message) {
        message = this.$t('api.error.general_error');
      }
      flashMessage(this.$store, this.$t(message), 'danger');
    },
    setUsedInputSets(usedInputSets) {
      this.usedInputSets = usedInputSets;
    },
  },
};
</script>

<style lang="scss">
  .checkin {
    text-align: center;
    width: calc(100% - 4rem);
    max-width: 50rem;
  }
  .wholepage-loading {
    width: 100%;
    height: 100%;
    background-color: $body-bg;
    position:fixed;
    top:0; /* Keep at the very top of the viewport */
    left:0;
    z-index: 100;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
</style>
