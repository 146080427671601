<script>
import _ from 'lodash';

export default {
  name: 'CheckInFormBaseContent',
  mounted() {
    if (this.defaultValues) {
      _.each(this.defaultValues || {}, (value, key) => {
        this[key] = value;
      });
    }
  },
  props: {
    invalid: {
      type: Boolean,
      required: false,
    },
    formSending: {
      type: Boolean,
      required: false,
    },
    alreadySent: {
      type: Boolean,
      required: false,
    },
    defaultValues: {
      type: Object,
      required: false,
    },
  },
};
</script>
