<template>
  <div class="c-camera-feed">
    <div class="c-camera-feed__viewer">
      <div v-if="scanInfo" class="c-camera-feed__card-scan-info">
        {{ scanInfo }}
      </div>
      <div :class="{'c-camera-feed__card-border': showBorder}" />
      <video ref="videoPlayer" class="video-player" width="100%" />

      <div v-if="successAnimation" class="svg-container">
        <svg class="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
          <circle class="circle" fill="#5bb543" cx="24" cy="24" r="22"/>
          <path class="tick" fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M14 27l5.917 4.917L34 17"/>
        </svg>
      </div>

    </div>
    <ButtonInput type="button" @click="takePhoto" :disabled="loading" :loading="loading">
      {{ $t('checkin.action.scan.take_photo') }}
    </ButtonInput>
    <div class="c-camera-feed__stage">
      <canvas ref="canvas" />
    </div>
  </div>
</template>

<script>
import stopMediaStream from 'stop-media-stream';
import ButtonInput from '@/components/Form/Input/ButtonInput';

export default {
  name: 'camera',
  components: {
    ButtonInput,
  },
  props: {
    showBorder: {
      type: Boolean,
      default: false,
    },
    scanInfo: {
      type: String,
      default: null,
    },
    side: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      loading: false,
      successAnimation: false,
      stream: null,
      base64data: null,
    };
  },
  watch: {
    side() {
      this.successAnimation = false;
      this.loading = false;
    },
  },
  async mounted() {
    const constraints = {
      video: {
        facingMode: 'environment',
      },
      audio: false,
    };

    const camera = await navigator
      .mediaDevices
      .getUserMedia(constraints)
      .catch((err) => {
        if (err.name && err.name === 'NotAllowedError') {
          console.log('!!! Not alowed camera !!!');
        }
      });
    if (camera) {
      this.setDevice(camera);
    }
  },
  beforeDestroy() {
    stopMediaStream(this.$refs.videoPlayer.srcObject);
    if (this.stream) {
      stopMediaStream(this.stream);
    }
  },
  methods: {
    async setDevice(stream) {
      this.$refs.videoPlayer.setAttribute('autoplay', '');
      this.$refs.videoPlayer.setAttribute('muted', '');
      this.$refs.videoPlayer.setAttribute('playsinline', '');
      this.stream = stream;

      let {width, height} = stream.getTracks()[0].getSettings();

      this.$refs.canvas.width = width;
      this.$refs.canvas.height = height;
      this.$refs.videoPlayer.srcObject = stream;

      await this.$refs.videoPlayer.play();

      this.$emit('loading', false);
    },
    async takePhoto() {
      this.loading = true;
      const context = this.$refs.canvas.getContext('2d');
      await context.drawImage(this.$refs.videoPlayer, 0, 0);
      // await this.destroyMediaStream();
      await this.$refs.canvas.toBlob(this.convertToBase64);
      /* setTimeout(() => {
        this.successAnimation = false;
        this.loading = false;
      }, 2000); */
    },
    reset() {
      this.base64data = null;
      this.$emit('input', null);
    },
    async convertToBase64(file) {
      const $el = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = await async function() {
        const base64data = reader.result;
        $el.base64data = base64data;

        await $el.playSuccessAnimation();
        $el.$emit('input', base64data);
      };
    },
    playSuccessAnimation() {
      this.successAnimation = true;
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .svg-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: $secondary-body-bg;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 7rem;
      height: 7rem;
    }
  }

  .video-player {
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg);
    -moz-transform:rotateY(180deg);
  }

  @supports (animation: grow .5s cubic-bezier(.25, .25, .25, 1) forwards) {
    .tick {
      stroke-opacity: 0;
      stroke-dasharray: 29px;
      stroke-dashoffset: 29px;
      animation: draw .5s cubic-bezier(.25, .25, .25, 1) forwards;
      animation-delay: .6s
    }

    .circle {
      fill-opacity: 0;
      stroke: $primary-color;
      stroke-width: 16px;
      transform-origin: center;
      transform: scale(0);
      animation: grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards;
    }
  }

  @keyframes grow {
    60% {
      transform: scale(.8);
      stroke-width: 4px;
      fill-opacity: 0;
    }
    100% {
      transform: scale(.9);
      stroke-width: 8px;
      fill-opacity: 1;
      fill: $primary-color;
    }
  }

  @keyframes draw {
    0%, 100% { stroke-opacity: 1; }
    100% { stroke-dashoffset: 0; }
  }
</style>
