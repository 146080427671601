<template>
  <div class="check-in-form">
    <ValidationObserver
        tag="form"
        class="checkin-form"
        ref="personalDetailsForm"
        onkeydown="return event.key != 'Enter';"
        :class="{'hidden': step !== 'personalDetails'}"
        v-slot="{ invalid }"
    >
      <PersonalDetails
          :invalid="invalid"
          :formSending="formSending"
          :alreadySent="alreadySent"
          :defaultValues="checkInDefaultValues"
          @submit="personalDataSubmit"
          @back="previousStep"
      />
    </ValidationObserver>

    <ValidationObserver
        tag="form"
        class="checkin-form"
        ref="documentDetailsForm"
        onkeydown="return event.key != 'Enter';"
        :class="{'hidden': step !== 'documentDetails'}"
        v-slot="{ invalid }"
    >
      <DocumentDetails
          :invalid="invalid"
          :formSending="formSending"
          :alreadySent="alreadySent"
          :defaultValues="checkInDefaultValues"
          :visaIsRequired="visaIsRequired"
          @submit="documentDataSubmit"
          @back="previousStep"
      />
    </ValidationObserver>

    <ValidationObserver
        tag="form"
        class="checkin-form"
        ref="addressDetailsForm"
        onkeydown="return event.key != 'Enter';"
        :class="{'hidden': step !== 'addressDetails'}"
        v-slot="{ invalid }"
    >
      <AddressDetails
          :invalid="invalid"
          :formSending="formSending"
          :alreadySent="alreadySent"
          :defaultValues="checkInDefaultValues"
          :selectedNationality="nationality"
          @submit="addressDataSubmit"
          @back="previousStep"
      />
    </ValidationObserver>

    <ValidationObserver
        tag="form"
        class="checkin-form"
        ref="otherDetailsForm"
        onkeydown="return event.key != 'Enter';"
        :class="{'hidden': step !== 'otherDetails'}"
        v-slot="{ invalid }"
    >
      <OtherDetails
          :invalid="invalid"
          :formSending="formSending"
          :alreadySent="alreadySent"
          :defaultValues="checkInDefaultValues"
          @submit="otherDataSubmit"
          @back="previousStep"
      />
    </ValidationObserver>

    <ValidationObserver
        tag="form"
        class="checkin-form"
        ref="otherDetailsSecondForm"
        onkeydown="return event.key != 'Enter';"
        :class="{'hidden': step !== 'otherDetailsSecond'}"
        v-slot="{ invalid }"
    >
      <OtherDetailsSecond
          :invalid="invalid"
          :formSending="formSending"
          :alreadySent="alreadySent"
          :defaultValues="checkInDefaultValues"
          @submit="otherDataSubmit"
          @back="previousStep"
      />
    </ValidationObserver>

    <VisaScan
        :class="{'hidden': step !== 'visaScan'}"
        :invalid="false"
        :formSending="formSending"
        :alreadySent="alreadySent"
        :defaultValues="checkInDefaultValues"
        :visaIsRequired="visaIsRequired"
        @submit="visaScanSubmit"
        @back="previousStep"
    />

    <DocumentScan
        :class="{'hidden': step !== 'documentScan'}"
        :invalid="false"
        :formSending="formSending"
        :alreadySent="alreadySent"
        :defaultValues="checkInDefaultValues"
        :typeOfDocumentSelected="typeOfDocument"
        @submit="documentScanSubmit"
        @back="previousStep"
    />

    <ValidationObserver
        tag="form"
        class="checkin-form"
        ref="purposeOfStayForm"
        onkeydown="return event.key != 'Enter';"
        :class="{'hidden': step !== 'purposeOfStay'}"
        v-slot="{ invalid }"
    >
      <PurposeOfStay
          :invalid="invalid"
          :formSending="formSending"
          :alreadySent="alreadySent"
          :defaultValues="checkInDefaultValues"
          @submit="purposeOfStayDataSubmit"
          @back="previousStep"
      />
    </ValidationObserver>

    <ValidationObserver
        tag="form"
        class="terms-and-conditions-form"
        ref="termsAndConditionsForm"
        onkeydown="return event.key != 'Enter';"
        :class="{'hidden': step !== 'termsAndConditions'}"
        v-slot="{ invalid }"
    >
      <TermsAndConditions
          :invalid="invalid"
          :formSending="formSending"
          :alreadySent="alreadySent"
          @submit="termsAndConditionsAcceptance"
          @back="previousStep"
      />
    </ValidationObserver>

    <Signature
        v-if="step === 'signature'"
        :class="{'hidden': step !== 'signature'}"
        :formSending="formSending"
        @submit="signatureSubmit"
        @back="previousStep"
    />

  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {
  GET_CHECKIN_PURPOSES_OF_STAY,
  GET_CHECKIN_VISA_REQUIREMENTS,
  GET_CHECKIN_INPUTS,
  GET_CHECKIN_TERMS_AND_CONDITIONS,
} from '@/store/property';
import {
  GET_RESERVATION_DETAIL,
  GET_CHECKINS,
} from '@/store/reservation';
import PersonalDetails from './CheckInFormContents/PersonalDetails';
import DocumentDetails from './CheckInFormContents/DocumentDetails';
import AddressDetails from './CheckInFormContents/AddressDetails';
import OtherDetails from './CheckInFormContents/OtherDetails';
import VisaScan from './CheckInFormContents/VisaScan';
import DocumentScan from './CheckInFormContents/DocumentScan';
import PurposeOfStay from './CheckInFormContents/PurposeOfStay.vue';
import OtherDetailsSecond from './CheckInFormContents/OtherDetailsSecond.vue';
import TermsAndConditions from './CheckInFormContents/TermsAndConditions.vue';
import Signature from './CheckInFormContents/Signature.vue';
import { logEvent } from '@/helpers/analytics';
import { checkInInputsSet } from '@/components/CheckIn/CheckInForm/consts';
import { getTranslated } from '@/helpers/translations';

export default {
  name: 'CheckInForm',
  components: {
    PersonalDetails,
    DocumentDetails,
    AddressDetails,
    OtherDetails,
    VisaScan,
    DocumentScan,
    PurposeOfStay,
    OtherDetailsSecond,
    TermsAndConditions,
    Signature,
  },
  mounted() {
    this.$emit('usedInputSets', this.usedInputSets);
  },
  data() {
    const steps = [
      'personalDetails',
      'documentDetails',
      'addressDetails',
      'otherDetails',
      'visaScan',
      'documentScan',
      'purposeOfStay',
      'termsAndConditions',
      'signature',
    ];

    return ({
      step: steps[0],
      steps: steps,
      checkInData: {},
      alreadySent: false,
      formSending: false,
      nationality: '',
      typeOfDocument: null,
    });
  },
  watch: {
    nationality() {
      this.$emit('usedInputSets', this.usedInputSets);
    },
  },
  computed: {
    ...mapGetters({
      purposesOfStay: GET_CHECKIN_PURPOSES_OF_STAY,
      visaRequirements: GET_CHECKIN_VISA_REQUIREMENTS,
      checkInInputs: GET_CHECKIN_INPUTS,
      reservation: GET_RESERVATION_DETAIL,
      checkIns: GET_CHECKINS,
    }),
    checkInDefaultValues() {
      if (!this.checkIns || this.checkIns.length === 0) {
        return {
          firstName: this.reservation.first_name || '',
          lastName: this.reservation.last_name || '',
          email: this.reservation.email || '',
          phone: this.reservation.phone || '',
        };
      }
      return {};
    },
    purposesOfStayUsed() {
      return this.purposesOfStay && this.purposesOfStay.length > 0;
    },
    visaIsRequired() {
      if (!this.nationality) {
        return false;
      }
      return !!_.find(this.visaRequirements, (visaCode) => visaCode === this.nationality);
    },
    usedInputSets() {
      const usedInputSets = {};
      _.each(checkInInputsSet, (inputSet, inputSetName) => {
        _.each(inputSet, (inputName) => {
          if (_.find(this.checkInInputs, (checkInInputSetting) => checkInInputSetting.key === inputName)) {
            usedInputSets[inputSetName] = inputSetName;
          }
        });
      });

      if (this.visaIsRequired) {
        usedInputSets['visaScan'] = 'visaScan';
        usedInputSets['documentDetails'] = 'documentDetails';
      } else {
        delete usedInputSets['visaScan'];
      }

      const termsAndConditions = getTranslated(this.$store.getters[GET_CHECKIN_TERMS_AND_CONDITIONS], this.$i18n.locale);
      usedInputSets['purposeOfStay'] = 'purposeOfStay';
      if (termsAndConditions) {
        usedInputSets['termsAndConditions'] = 'termsAndConditions';
      }
      usedInputSets['signature'] = 'signature';
      return usedInputSets;
    },
  },
  methods: {
    personalDataSubmit(data) {
      this.checkInFormSendAndValidate(data, 'personalDetailsForm')
        .then((isValid) => {
          if (isValid) {
            logEvent('onCheckInAddPersonalDetailsSubmitClick');
          }
        });
    },
    documentDataSubmit(data) {
      this.checkInFormSendAndValidate(data, 'documentDetailsForm')
        .then((isValid) => {
          if (isValid) {
            logEvent('onCheckInAddDocumentDetailsSubmitClick');
          }
        });
    },
    addressDataSubmit(data) {
      const inputSettings = _.find(this.checkInInputs, (checkInInput) => checkInInput.key === 'phone');

      if (inputSettings) {
        if ((inputSettings.mandatory && !data.phoneValid) || (data.phone && !data.phoneValid)) {
          this.alreadySent = true;
          return this.$refs['addressDetailsForm'].validate();
        }
      }

      this.checkInFormSendAndValidate(data, 'addressDetailsForm')
        .then((isValid) => {
          if (isValid) {
            logEvent('onCheckInAddAddressDetailsSubmitClick');
          }
        });
    },
    otherDataSubmit(data) {
      this.checkInFormSendAndValidate(data, 'otherDetailsForm')
        .then((isValid) => {
          if (isValid) {
            logEvent('onCheckInAddOtherDetailsSubmitClick');
          }
        });
    },
    otherDataSecondSubmit(data) {
      this.checkInFormSendAndValidate(data, 'otherDetailsSecondForm')
        .then((isValid) => {
          if (isValid) {
            logEvent('onCheckInAddOtherDetailsSubmitClick');
          }
        });
    },
    visaScanSubmit(data) {
      _.each(data, (item, key) => {
        this.checkInData[key] = item;
      });

      this.nextStep();
      this.formSending = false;
      this.alreadySent = false;

      logEvent('onCheckInAddVisaScanSubmitClick');
    },
    documentScanSubmit(data) {
      _.each(data, (item, key) => {
        this.checkInData[key] = item;
      });

      this.nextStep();
      this.formSending = false;
      this.alreadySent = false;

      logEvent('onCheckInAddIDScanSubmitClick');
    },
    termsAndConditionsAcceptance(data) {
      if (!data.termsAgreement) {
        this.alreadySent = true;
        return;
      }

      this.checkInFormSendAndValidate(data, 'termsAndConditionsForm')
        .then((isValid) => {
          if (isValid) {
            logEvent('onCheckInAddTermsAndConditionsSubmitClick');
          }
        });
    },
    purposeOfStayDataSubmit(data) {
      _.each(data, (item, key) => {
        this.checkInData[key] = item;
      });

      if (!this.checkInData.agreement) {
        this.alreadySent = true;
        return;
      }

      if (this.purposesOfStayUsed) {
        if (!this.checkInData.purposeOfStay) {
          this.alreadySent = true;
          return;
        }
      }

      this.checkInFormSendAndValidate(data, 'purposeOfStayForm')
        .then((isValid) => {
          if (isValid) {
            logEvent('onCheckInAddPurposeOfStaySubmitClick');
          }
        });
    },
    signatureSubmit(data) {
      _.each(data, (item, key) => {
        this.checkInData[key] = item;
      });

      this.formSending = true;
      this.$emit('submit', this.checkInData);
    },
    checkInFormSendAndValidate(data, name) {
      return new Promise((resolve) => {
        this.formSending = true;
        this.alreadySent = true;

        _.each(data, (item, key) => {
          _.set(this.checkInData, key, item);
          if (key === 'nationality') {
            this.nationality = item;
          }
          if (key === 'typeOfDocument') {
            this.typeOfDocument = item;
          }
        });

        this.$refs[name].validate()
          .then((isValid) => {
            if (!isValid) {
              this.formSending = false;
              return resolve(isValid);
            }

            this.nextStep();
            this.formSending = false;
            this.alreadySent = false;
            return resolve(isValid);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    nextStep() {
      const currentIndex = this.steps.indexOf(this.step);

      let newStepindex = currentIndex + 1;

      const nextStep = this.steps[newStepindex] || false;

      if (nextStep) {
        this.setStep(nextStep);

        if (!_.find(this.usedInputSets, (usedInputSetName) => nextStep === usedInputSetName)) {
          this.nextStep();
        }
      }
    },
    previousStep() {
      const currentIndex = this.steps.indexOf(this.step);

      let newStepindex = currentIndex - 1;

      const previousStep = this.steps[newStepindex] || false;
      if (previousStep) {
        this.setStep(previousStep);

        if (!_.find(this.usedInputSets, (usedInputSetName) => previousStep === usedInputSetName)) {
          this.previousStep();
        }
      }
    },
    setStep(newStep) {
      this.step = newStep;
      this.$emit('step', newStep);
    },
  },
};
</script>

<style lang="scss">
  .checkin-form {
    text-align: left;

    .form-checkin-block {
      display: flex;
      flex-wrap: wrap;
    }

    .form-input-block {
      flex: 1 1 auto;
      margin-right: 1rem;
    }
  }

  .check-in-form {
    .error-message {
      display: none !important;
    }
  }
</style>
