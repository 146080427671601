<template>
  <div>
    <div class="form-checkin-title">
      <h3>{{ $t('checkin.form.title.other_details') }}</h3>
    </div>
    <div class="form-checkin-block">
      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :defaultValue="defaultValues['placeOfCountryEnter'] || null"
          @input="(value) => placeOfCountryEnter = value"
          :parameters="{
            name: 'placeOfCountryEnter',
            title: $t('checkin.form.placeOfCountryEnter.label'),
            placeholder: $t('checkin.form.placeOfCountryEnter.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :defaultValue="defaultValues['mothersName'] || null"
          @input="(value) => mothersName = value"
          :parameters="{
            name: 'mothersName',
            title: $t('checkin.form.mothersName.label'),
            placeholder: $t('checkin.form.mothersName.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :defaultValue="defaultValues['placeOfBirth'] || null"
          @input="(value) => placeOfBirth = value"
          :parameters="{
            name: 'placeOfBirth',
            title: $t('checkin.form.placeOfBirth.label'),
            placeholder: $t('checkin.form.placeOfBirth.placeholder'),
          }"
      />
    </div>

    <div class="form-button-center">
      <ButtonInput type="button" view="outline" :loading="formSending" @click="$emit('back')">
        {{ $t('checkin.action.back') }}
      </ButtonInput>

      <ButtonInput type="button" :disablesd="invalid" :loading="formSending" @click="onSubmit">
        {{ $t('checkin.action.continue') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import CheckInFormBaseContent from './CheckInFormBaseContent';
import CheckInFormInput from '../CheckInFormInput';
import {
  checkInInputsSet,
} from '../consts';

export default {
  name: 'OtherDetailsSecond',
  extends: CheckInFormBaseContent,
  components: {
    ButtonInput,
    CheckInFormInput,
  },
  data() {
    const data = {};
    _.each(checkInInputsSet['otherDetailsSecond'], (itemKey) => {
      data[itemKey] = '';
    });

    return ({
      ...data,
    });
  },
  methods: {
    onSubmit() {
      const data = _.clone(this._data);
      this.$emit('submit', data);
    },
  },
};
</script>
