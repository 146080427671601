import _ from 'lodash';
import countries from '@/constants/countries';

export const statesSet = _.chain(countries)
  .map((country) => {
    const countryName = country.name
      .replace(/[ ]/g, '_')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^A-Z_]/ig, '')
      .toLowerCase();
    return {
      label: 'country.' + countryName,
      value: country.alpha2Code,
    };
  })
  .orderBy('label')
  .value();

export const gendersSet = [
  {
    label: 'checkin.form.gender.male',
    value: 'M',
  },
  {
    label: 'checkin.form.gender.female',
    value: 'F',
  },
];

export const personalTitlesSet = [
  {
    label: 'checkin.form.title.mrs',
    value: 'mrs',
  },
  {
    label: 'checkin.form.title.ms',
    value: 'ms',
  },
  {
    label: 'checkin.form.title.mr',
    value: 'mr',
  },
];

export const typesOfDocumentSet = [
  {
    label: 'checkin.form.document_type.passport',
    value: 'passport',
  },
  {
    label: 'checkin.form.document_type.local_id',
    value: 'id',
  },
];

export const checkInInputsSet = {
  'personalDetails': [
    'title',
    'firstName',
    'middleName',
    'lastName',
    'nationality',
    'gender',
    'dateOfBirth',
  ],
  'documentDetails': [
    'iDNumber',
    'passportIssueDate',
    'passportExpirationDate',
    'countryIssuingPassport',
    'typeOfDocument',
    'visaNumber',
  ],
  'addressDetails': [
    'phone',
    'email',
    'residence',
    'street',
    'address',
    'city',
    'zip',
  ],
  'otherDetails': [
    'carPlateNumber',
    'numberOfFlight',
    'timeOfArrival',
    'occupation',
    'nextDestination',
    'dateOfCountryEnter',
  ],
  'otherDetailsSecond': [
    'placeOfCountryEnter',
    'mothersName',
    'placeOfBirth',
  ],
  'visaScan': [
    'scanVisa',
  ],
  'documentScan': [
    'idScan',
    'idScanSecond',
  ],
  'signature': [
    'signature',
  ],
};
