<template>
  <div v-if="visible" :class="`form-input-block ${additionalClass ? `form-input-block--${additionalClass}` : ''}`">
    <ValidationProvider :name="parameters.name || ''"
        :rules="(required ? 'required' : '') + (validationRules ? '|' + validationRules : '')"
        v-slot="{ errors }
    ">
      <component v-if="componentType === 'PhoneInput'"
          v-bind:is="componentType"
          v-model="value"
          :required="required"
          :error="alreadySent ? (forceError ? forceError : errors[0])  : null"
          v-bind="parameters"
          @input="phoneValueUpdate"
      />
      <component v-else-if="parameters.type !== 'radio'"
          v-bind:is="componentType"
          v-model="value"
          :required="required"
          :error="alreadySent ? (forceError ? forceError : errors[0])  : null"
          v-bind="parameters"
          @input="changeValue"
      />
      <component v-else
          v-bind:is="componentType"
          :value="parameters.value"
          :error="alreadySent ? (forceError ? forceError : errors[0])  : null"
          v-bind="parameters"
          @input="$emit('input', parameters.value)"
      />
    </ValidationProvider>
  </div>
</template>

<script>
import _ from 'lodash';
import Input from '@/components/Form/Input/Input';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import SelectInput from '@/components/Form/Input/SelectInput';
import DateInput from '@/components/Form/Input/DateInput';
import TimeInput from '@/components/Form/Input/TimeInput';
import PhoneInput from '@/components/Form/Input/PhoneInput';
import { GET_CHECKIN_INPUTS } from '@/store/property';

export default {
  components: {
    Input,
    ButtonInput,
    SelectInput,
    DateInput,
    TimeInput,
    PhoneInput,
  },
  props: {
    defaultValue: {
      required: false,
      default: null,
    },
    alreadySent: {
      type: Boolean,
      required: false,
    },
    componentType: {
      type: String,
      required: true,
      default: 'Input',
    },
    additionalClass: {
      type: String,
      default: '',
    },
    parameters: {
      type: Object,
      required: false,
    },
    validationRules: {
      type: String,
      default: '',
    },
    forceRequired: {
      type: Boolean,
      default: false,
    },
    forceError: {
      type: String,
      default: null,
    },
  },
  data() {
    const checkInInputs = this.$store.getters[GET_CHECKIN_INPUTS];
    const inputSettings = _.find(checkInInputs, (checkInInput) =>
      checkInInput.key === this.parameters.name);

    return {
      value: this.defaultValue || (this.parameters.type === 'radio' ? false : ''),
      inputSettings: inputSettings,
    };
  },
  computed: {
    required() {
      return this.forceRequired || (this.inputSettings ? !!this.inputSettings.mandatory : false);
    },
    visible() {
      return this.forceRequired || !!this.inputSettings;
    },
  },
  methods: {
    phoneValueUpdate(value) {
      this.value = value.value;
      this.$emit('input', value);
    },
    changeValue() {
      this.$emit('input', this.value);
    },
  },
};
</script>
