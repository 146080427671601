<template>
  <div>
    <div class="form-checkin-title">
      <h3>{{ $t('checkin.form.title.personal_details') }}</h3>
    </div>
    <div class="form-checkin-block">
      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="SelectInput"
          additionalClass="triple"
          :defaultValue="defaultValues['title'] || null"
          @input="(value) => title = value"
          :parameters="{
            name: 'title',
            title: $t('checkin.form.title.label'),
            placeholder: $t('checkin.form.title.placeholder'),
            options: personalTitlesSet,
            wholepage: false,
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          validationRules="min:2"
          additionalClass="triple"
          :defaultValue="defaultValues['firstName'] || null"
          @input="(value) => firstName = value"
          :parameters="{
            name: 'firstName',
            title: $t('checkin.form.firstname.label'),
            placeholder: $t('checkin.form.firstname.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          validationRules="min:2"
          additionalClass="triple"
          :defaultValue="defaultValues['middleName'] || null"
          @input="(value) => middleName = value"
          :parameters="{
            name: 'middleName',
            title: $t('checkin.form.middlename.label'),
            placeholder: $t('checkin.form.middlename.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          validationRules="min:2"
          additionalClass="triple"
          :defaultValue="defaultValues['lastName'] || null"
          @input="(value) => lastName = value"
          :parameters="{
            name: 'lastName',
            title: $t('checkin.form.surname.label'),
            placeholder: $t('checkin.form.surname.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="SelectInput"
          :defaultValue="defaultValues['nationality'] || null"
          @input="(value) => nationality = value"
          :parameters="{
            name: 'nationality',
            title: $t('checkin.form.nationality.label'),
            placeholder: $t('checkin.form.select.select_option') + ' ' + $t('checkin.form.nationality.label').toLowerCase(),
            options: statesSet,
            orderBy: 'label',
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="DateInput"
          validationRules='date'
          :defaultValue="defaultValues['dateOfBirth'] || null"
          @input="(value) => dateOfBirth = value"
          :parameters="{
            name: 'dateOfBirth',
            title: $t('checkin.form.dateOfBirth.label'),
            placeholder: $t('checkin.form.dateOfBirth.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="SelectInput"
          :defaultValue="defaultValues['gender'] || null"
          @input="(value) => gender = value"
          :parameters="{
            name: 'gender',
            title: $t('checkin.form.gender.label'),
            placeholder: $t('checkin.form.select.select_option') + ' ' + $t('checkin.form.gender.label').toLowerCase(),
            options: gendersSet,
            wholepage: false,
          }"
      />
    </div>

    <div class="form-button-center">
      <ButtonInput type="button" :disablesd="invalid" :loading="formSending" @click="onSubmit">
        {{ $t('checkin.action.continue') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import CheckInFormBaseContent from './CheckInFormBaseContent';
import CheckInFormInput from '../CheckInFormInput';
import {
  checkInInputsSet,
  statesSet,
  gendersSet,
  personalTitlesSet,
} from '../consts';

export default {
  name: 'PersonalDetails',
  extends: CheckInFormBaseContent,
  components: {
    ButtonInput,
    CheckInFormInput,
  },
  data() {
    const data = {};
    _.each(checkInInputsSet['personalDetails'], (itemKey) => {
      data[itemKey] = '';
    });

    return ({
      ...data,
      statesSet,
      gendersSet,
      personalTitlesSet,
    });
  },
  methods: {
    onSubmit() {
      const data = _.clone(this._data);
      delete data['statesSet'];
      delete data['gendersSet'];
      delete data['personalTitlesSet'];
      this.$emit('submit', data);
    },
  },
};
</script>
