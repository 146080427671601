var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"check-in-form"},[_c('ValidationObserver',{ref:"personalDetailsForm",staticClass:"checkin-form",class:{'hidden': _vm.step !== 'personalDetails'},attrs:{"tag":"form","onkeydown":"return event.key != 'Enter';"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('PersonalDetails',{attrs:{"invalid":invalid,"formSending":_vm.formSending,"alreadySent":_vm.alreadySent,"defaultValues":_vm.checkInDefaultValues},on:{"submit":_vm.personalDataSubmit,"back":_vm.previousStep}})]}}])}),_c('ValidationObserver',{ref:"documentDetailsForm",staticClass:"checkin-form",class:{'hidden': _vm.step !== 'documentDetails'},attrs:{"tag":"form","onkeydown":"return event.key != 'Enter';"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('DocumentDetails',{attrs:{"invalid":invalid,"formSending":_vm.formSending,"alreadySent":_vm.alreadySent,"defaultValues":_vm.checkInDefaultValues,"visaIsRequired":_vm.visaIsRequired},on:{"submit":_vm.documentDataSubmit,"back":_vm.previousStep}})]}}])}),_c('ValidationObserver',{ref:"addressDetailsForm",staticClass:"checkin-form",class:{'hidden': _vm.step !== 'addressDetails'},attrs:{"tag":"form","onkeydown":"return event.key != 'Enter';"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('AddressDetails',{attrs:{"invalid":invalid,"formSending":_vm.formSending,"alreadySent":_vm.alreadySent,"defaultValues":_vm.checkInDefaultValues,"selectedNationality":_vm.nationality},on:{"submit":_vm.addressDataSubmit,"back":_vm.previousStep}})]}}])}),_c('ValidationObserver',{ref:"otherDetailsForm",staticClass:"checkin-form",class:{'hidden': _vm.step !== 'otherDetails'},attrs:{"tag":"form","onkeydown":"return event.key != 'Enter';"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('OtherDetails',{attrs:{"invalid":invalid,"formSending":_vm.formSending,"alreadySent":_vm.alreadySent,"defaultValues":_vm.checkInDefaultValues},on:{"submit":_vm.otherDataSubmit,"back":_vm.previousStep}})]}}])}),_c('ValidationObserver',{ref:"otherDetailsSecondForm",staticClass:"checkin-form",class:{'hidden': _vm.step !== 'otherDetailsSecond'},attrs:{"tag":"form","onkeydown":"return event.key != 'Enter';"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('OtherDetailsSecond',{attrs:{"invalid":invalid,"formSending":_vm.formSending,"alreadySent":_vm.alreadySent,"defaultValues":_vm.checkInDefaultValues},on:{"submit":_vm.otherDataSubmit,"back":_vm.previousStep}})]}}])}),_c('VisaScan',{class:{'hidden': _vm.step !== 'visaScan'},attrs:{"invalid":false,"formSending":_vm.formSending,"alreadySent":_vm.alreadySent,"defaultValues":_vm.checkInDefaultValues,"visaIsRequired":_vm.visaIsRequired},on:{"submit":_vm.visaScanSubmit,"back":_vm.previousStep}}),_c('DocumentScan',{class:{'hidden': _vm.step !== 'documentScan'},attrs:{"invalid":false,"formSending":_vm.formSending,"alreadySent":_vm.alreadySent,"defaultValues":_vm.checkInDefaultValues,"typeOfDocumentSelected":_vm.typeOfDocument},on:{"submit":_vm.documentScanSubmit,"back":_vm.previousStep}}),_c('ValidationObserver',{ref:"purposeOfStayForm",staticClass:"checkin-form",class:{'hidden': _vm.step !== 'purposeOfStay'},attrs:{"tag":"form","onkeydown":"return event.key != 'Enter';"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('PurposeOfStay',{attrs:{"invalid":invalid,"formSending":_vm.formSending,"alreadySent":_vm.alreadySent,"defaultValues":_vm.checkInDefaultValues},on:{"submit":_vm.purposeOfStayDataSubmit,"back":_vm.previousStep}})]}}])}),_c('ValidationObserver',{ref:"termsAndConditionsForm",staticClass:"terms-and-conditions-form",class:{'hidden': _vm.step !== 'termsAndConditions'},attrs:{"tag":"form","onkeydown":"return event.key != 'Enter';"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('TermsAndConditions',{attrs:{"invalid":invalid,"formSending":_vm.formSending,"alreadySent":_vm.alreadySent},on:{"submit":_vm.termsAndConditionsAcceptance,"back":_vm.previousStep}})]}}])}),(_vm.step === 'signature')?_c('Signature',{class:{'hidden': _vm.step !== 'signature'},attrs:{"formSending":_vm.formSending},on:{"submit":_vm.signatureSubmit,"back":_vm.previousStep}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }