<template>
  <label class="form-control">
    <span v-if="title" :class="{'required': required}">{{ title }}</span>
    <div class="date-time-selection">
      <SelectInput
          v-model="year"
          :name="`date_year_${index}`"
          :placeholder="$t('form.date_input.year_select_placeholder')"
          :error="error"
          :options="yearsSet"
          class="date-time-select date-time-select--year"
          @input="changeDate"
      />
      <SelectInput
          v-model="month"
          :name="`date_month_${index}`"
          :placeholder="$t('form.date_input.month_select_placeholder')"
          :error="error"
          :options="monthsSet"
          class="date-time-select date-time-select--month"
          @input="changeDate"
      />
      <SelectInput
          v-model="day"
          :name="`date_day_${index}`"
          :placeholder="$t('form.date_input.day_select_placeholder')"
          :error="error"
          :options="daysSet"
          class="date-time-select date-time-select--day"
          @input="changeDate"
      />
    </div>
    <span v-if="error" class="error-message">
      <slot name="error">
        {{ error }}
      </slot>
    </span>
  </label>
</template>

<script>
import moment from 'moment';
import Input from './Input';
import SelectInput from './SelectInput';

export default {
  name: 'DateInput',
  extends: Input,
  components: {
    SelectInput,
  },
  mounted() {
    if (this.value) {
      const date = new moment(this.value);
      if (date.isValid()) {
        this.year = date.format('Y');
        this.month = date.format('M');
        this.day = date.format('D');

        this.changeDate();
      }
    }
  },
  data() {
    const daysSet = [];
    for (let i = 1; i <= 31; i++) {
      daysSet.push({
        label: i,
        value: i,
      });
    }

    const monthsSet = [
      {
        label: 'month.january',
        value: 1,
      },
      {
        label: 'month.february',
        value: 2,
      },
      {
        label: 'month.march',
        value: 3,
      },
      {
        label: 'month.april',
        value: 4,
      },
      {
        label: 'month.may',
        value: 5,
      },
      {
        label: 'month.june',
        value: 6,
      },
      {
        label: 'month.july',
        value: 7,
      },
      {
        label: 'month.august',
        value: 8,
      },
      {
        label: 'month.september',
        value: 9,
      },
      {
        label: 'month.october',
        value: 10,
      },
      {
        label: 'month.november',
        value: 11,
      },
      {
        label: 'month.december',
        value: 12,
      },
    ];

    const currentYear = (new Date()).getFullYear();
    const yearsSet = [];
    for (let i = currentYear; i >= currentYear - 130; i--) {
      yearsSet.push({
        label: i,
        value: i,
      });
    }

    return {
      index: Math.round(Math.random()*1000000000),
      day: null,
      month: null,
      year: null,
      daysSet,
      monthsSet,
      yearsSet,
    };
  },
  methods: {
    changeDate() {
      if (this.year && this.month && this.day) {
        const month = this.month < 10 ? '0' + this.month : this.month;
        const day = this.day < 10 ? '0' + this.day : this.day;
        this.$emit('input', this.year + '-' + month + '-' + day);
        return;
      }
      this.$emit('input', '');
    },
  },
};
</script>
