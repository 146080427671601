<template>
  <div>
    <div class="form-checkin-title">
      <h1>{{ $t('checkin.form.title.terms_and_conditions') }}</h1>
    </div>
    <div class="form-checkin-block terms-conditions-content">
      {{ conditions }}
    </div>

    <CheckInFormInput
        additionalClass="wide"
        :alreadySent="alreadySent"
        componentType="Input"
        forceRequired
        @input="() => termsAgreement = !termsAgreement"
        :parameters="{
          name: 'termsAgreement',
          type: 'checkbox',
          title: $t('checkin.form.terms_agreement'),
        }"
    />

    <div class="form-button-center">
      <ButtonInput type="button" view="outline" @click="$emit('back')">
        {{ $t('checkin.action.back') }}
      </ButtonInput>

      <ButtonInput type="button" @click="onSubmit">
        {{ $t('checkin.action.continue') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { getTranslated } from '@/helpers/translations';
import { GET_CHECKIN_TERMS_AND_CONDITIONS } from '@/store/property';
import CheckInFormBaseContent from './CheckInFormBaseContent';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import CheckInFormInput from '../CheckInFormInput';

export default {
  name: 'OtherDetails',
  extends: CheckInFormBaseContent,
  components: {
    ButtonInput,
    CheckInFormInput,
  },
  data() {
    return {
      termsAgreement: false,
    };
  },
  computed: {
    conditions() {
      return getTranslated(this.$store.getters[GET_CHECKIN_TERMS_AND_CONDITIONS], this.$i18n.locale);
    },
  },
  methods: {
    onSubmit() {
      const data = _.clone(this._data);
      this.$emit('submit', data);
    },
  },
};
</script>

<style lang="scss">
.terms-conditions-content {
  max-height: 39vh;
  overflow: scroll;
  overflow-x: hidden;
  margin-bottom: 2rem;
}
</style>
