<template>
  <div>
    <div class="form-checkin-title">
      <h1>
        {{ this.scanVisa ? $t('checkin.form.title.document_scan_check') : $t('checkin.form.title.visa_scan') }}
      </h1>
    </div>
    <div class="form-checkin-block">
      <Camera
          v-if="!scanVisa"
          :showBorder="false"
          @loading="(status) => loading = status"
          @input="processScan"
      />
      <div v-else class="scan-preview-block">
        <img :src="scanVisa" alt="Scan document preview" class="scan-preview" />

        <div class="reset-button-box">
          <ButtonInput type="button" @click="resetScan" view="outline">
            {{ $t('checkin.action.scan.reset') }}
          </ButtonInput>
        </div>
      </div>
    </div>

    <div class="form-button-center" v-if="this.scanVisa">
      <ButtonInput type="button" view="outline" :loading="formSending" @click="$emit('back')">
        {{ $t('checkin.action.back') }}
      </ButtonInput>

      <ButtonInput type="button" :disablesd="invalid" :loading="formSending" @click="onSubmit">
        {{ $t('checkin.action.continue') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Jimp from 'jimp';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import CheckInFormBaseContent from './CheckInFormBaseContent';
import {
  checkInInputsSet,
} from '../consts';
import Camera from '@/components/Camera';
import WatermarkImage from '@/assets/images/watermark.png';

export default {
  name: 'VisaDetails',
  extends: CheckInFormBaseContent,
  components: {
    ButtonInput,
    Camera,
  },
  props: {
    visaIsRequired: {
      type: Boolean,
      required: true,
    },
    nationalitySelected: {
      type: String,
      required: false,
    },
  },
  data() {
    const data = {};
    _.each(checkInInputsSet['visaScan'], (itemKey) => {
      data[itemKey] = '';
    });

    return ({
      ...data,
      loading: true,
    });
  },
  methods: {
    async processScan(base64data) {
      this.loading = true;

      const splitted = base64data.split(',');
      const [, splittedFileBase64] = splitted;

      Jimp.read(Buffer.from(splittedFileBase64, 'base64')).then(async (image) => {
        image.quality(80);
        await image.resize(800, Jimp.AUTO);

        const mask = await Jimp.read(WatermarkImage);
        image.blit(mask, 0, 0);

        this.scanVisa = await image.getBase64Async(image.getMIME());
        this.loading = false;
      });
    },
    resetScan() {
      this.scanVisa = null;
      this.loading = false;
    },
    onSubmit() {
      const data = _.clone(this._data);
      delete data['loading'];
      this.$emit('submit', data);
    },
  },
};
</script>
