<template>
  <div>
    <div class="form-checkin-title purpose-title">
      <h3>{{ $t('checkin.form.purposeOfStay.label') }}</h3><h3 class='title-required'>*</h3>
    </div>
    <div class="form-checkin-block">
      <!-- <div v-if="purposesOfStaySet && purposesOfStaySet.length > 0">{{ $t('checkin.form.purposeOfStay.label') }}</div> -->
      <div class="clearfix" />
      <br />

      <div v-if="purposesOfStaySet && purposesOfStaySet.length > 0" class="purposes-of-stay-list">
        <CheckInFormInput
            v-for="purpose in purposesOfStaySet"
            :key="purpose.key"
            additionalClass="triple"
            :alreadySent="alreadySent"
            componentType="Input"
            forceRequired
            :forceError="purposeOfStay ? null : 'required'"
            @input="() => purposeOfStay = purpose.key"
            :parameters="{
              name: 'purposeOfStay',
              type: 'radio',
              title: purpose.title,
              value: purpose.key,
            }"
        />
      </div>

      <CheckInFormInput
          additionalClass="wide"
          :alreadySent="alreadySent"
          componentType="Input"
          forceRequired
          @input="() => agreement = !agreement"
          :parameters="{
            name: 'agreement',
            type: 'checkbox',
            title: $t('checkin.form.agreement'),
          }"
      />
    </div>

    <div class="form-button-center">
      <ButtonInput type="button" view="outline" :loading="formSending" @click="$emit('back')">
        {{ $t('checkin.action.back') }}
      </ButtonInput>

      <ButtonInput type="button" :disablesd="invalid" :loading="formSending" @click="onSubmit">
        {{ $t('checkin.action.continue') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import CheckInFormBaseContent from './CheckInFormBaseContent';
import CheckInFormInput from '../CheckInFormInput';
import { GET_CHECKIN_PURPOSES_OF_STAY } from '@/store/property';

export default {
  name: 'PurposeOfStay',
  extends: CheckInFormBaseContent,
  components: {
    ButtonInput,
    CheckInFormInput,
  },
  data() {
    return ({
      purposeOfStay: null,
      agreement: false,
    });
  },
  computed: {
    purposesOfStaySet() {
      const purposesOfStaySet = this.$store.getters[GET_CHECKIN_PURPOSES_OF_STAY] || false;
      if (!purposesOfStaySet) {
        return [];
      }

      for (var i = 0; i < purposesOfStaySet.length; i++) {
        let title = purposesOfStaySet[i].title;
        title = _.replace(title, /\(/g, '');
        title = _.replace(title, /\)/g, '');
        title = _.replace(title, / /g, '_');
        purposesOfStaySet[i].title = this.$t('checkin.form.purpose_of_stay.' + title);
      }

      return purposesOfStaySet;
    },
  },
  methods: {
    onSubmit() {
      const data = _.clone(this._data);
      this.$emit('submit', data);
    },
  },
};
</script>

<style lang="scss">
  .purposes-of-stay-list {
    margin-bottom: 2rem;
  }

  .title-required {
    color: #ff2b56;
  }

  .form-checkin-title.purpose-title {
    display: flex;
  }
</style>
