<template>
  <div>
    <div class="form-checkin-title">
      <h3>{{ $t('checkin.form.title.document_details') }}</h3>
    </div>
    <div class="form-checkin-block">
      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          validationRules="min:5"
          :defaultValue="defaultValues['iDNumber'] || null"
          @input="(value) => iDNumber = value"
          :parameters="{
            name: 'iDNumber',
            title: $t('checkin.form.iDNumber.label'),
            placeholder: $t('checkin.form.iDNumber.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="SelectInput"
          :defaultValue="defaultValues['typeOfDocument'] || null"
          @input="(value) => typeOfDocument = value"
          :parameters="{
            name: 'typeOfDocument',
            title: $t('checkin.form.typesOfDocument.label'),
            placeholder: $t('checkin.form.select.select_option') + ' ' + $t('checkin.form.typesOfDocument.label').toLowerCase(),
            options: typesOfDocumentSet,
            wholepage: false,
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="DateInput"
          validationRules='date'
          :defaultValue="defaultValues['passportIssueDate'] || null"
          @input="(value) => passportIssueDate = value"
          :parameters="{
            name: 'passportIssueDate',
            title: $t('checkin.form.passportIssueDate.label'),
            placeholder: $t('checkin.form.passportIssueDate.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="DateInput"
          validationRules='date'
          :defaultValue="defaultValues['passportExpirationDate'] || null"
          @input="(value) => passportExpirationDate = value"
          :parameters="{
            name: 'passportExpirationDate',
            title: $t('checkin.form.passportExpirationDate.label'),
            placeholder: $t('checkin.form.passportExpirationDate.placeholder'),
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="SelectInput"
          :defaultValue="defaultValues['countryIssuingPassport'] || null"
          @input="(value) => countryIssuingPassport = value"
          :parameters="{
            name: 'countryIssuingPassport',
            title: $t('checkin.form.countryIssuingPassport.label'),
            placeholder: $t('checkin.form.select.select_option') + ' ' + $t('checkin.form.countryIssuingPassport.label').toLowerCase(),
            options: statesSet,
            orderBy: 'label',
          }"
      />

      <CheckInFormInput
          :alreadySent="alreadySent"
          componentType="Input"
          :forceRequired="visaIsRequired"
          validationRules="min:7"
          :defaultValue="defaultValues['visaNumber'] || null"
          @input="(value) => visaNumber = value"
          :parameters="{
            name: 'visaNumber',
            title: $t('checkin.form.visaNumber.label'),
            placeholder: $t('checkin.form.visaNumber.placeholder'),
          }"
      />
    </div>

    <div class="form-button-center">
      <ButtonInput type="button" view="outline" :loading="formSending" @click="$emit('back')">
        {{ $t('checkin.action.back') }}
      </ButtonInput>

      <ButtonInput type="button" :disablesd="invalid" :loading="formSending" @click="onSubmit">
        {{ $t('checkin.action.continue') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import CheckInFormBaseContent from './CheckInFormBaseContent';
import CheckInFormInput from '../CheckInFormInput';
import {
  statesSet,
  checkInInputsSet,
  typesOfDocumentSet,
} from '../consts';

export default {
  name: 'DocumentDetails',
  extends: CheckInFormBaseContent,
  components: {
    ButtonInput,
    CheckInFormInput,
  },
  props: {
    visaIsRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const data = {};
    _.each(checkInInputsSet['documentDetails'], (itemKey) => {
      data[itemKey] = '';
    });

    return ({
      ...data,
      statesSet,
      typesOfDocumentSet,
    });
  },
  methods: {
    onSubmit() {
      const data = _.clone(this._data);
      delete data['statesSet'];
      delete data['typesOfDocumentSet'];
      this.$emit('submit', data);
    },
  },
};
</script>
