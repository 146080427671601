var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-checkin-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t('checkin.form.title.other_details')))])]),_c('div',{staticClass:"form-checkin-block"},[_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","defaultValue":_vm.defaultValues['placeOfCountryEnter'] || null,"parameters":{
          name: 'placeOfCountryEnter',
          title: _vm.$t('checkin.form.placeOfCountryEnter.label'),
          placeholder: _vm.$t('checkin.form.placeOfCountryEnter.placeholder'),
        }},on:{"input":function (value) { return _vm.placeOfCountryEnter = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","defaultValue":_vm.defaultValues['mothersName'] || null,"parameters":{
          name: 'mothersName',
          title: _vm.$t('checkin.form.mothersName.label'),
          placeholder: _vm.$t('checkin.form.mothersName.placeholder'),
        }},on:{"input":function (value) { return _vm.mothersName = value; }}}),_c('CheckInFormInput',{attrs:{"alreadySent":_vm.alreadySent,"componentType":"Input","defaultValue":_vm.defaultValues['placeOfBirth'] || null,"parameters":{
          name: 'placeOfBirth',
          title: _vm.$t('checkin.form.placeOfBirth.label'),
          placeholder: _vm.$t('checkin.form.placeOfBirth.placeholder'),
        }},on:{"input":function (value) { return _vm.placeOfBirth = value; }}})],1),_c('div',{staticClass:"form-button-center"},[_c('ButtonInput',{attrs:{"type":"button","view":"outline","loading":_vm.formSending},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" "+_vm._s(_vm.$t('checkin.action.back'))+" ")]),_c('ButtonInput',{attrs:{"type":"button","disablesd":_vm.invalid,"loading":_vm.formSending},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('checkin.action.continue'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }